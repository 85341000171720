import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { CryptoHelper } from 'dsilo-ui-components'
const SilentSSO = (props) => {
	const { emailCheckData } = props.login
	const getDecryptedConfig = async (encryptedConfig, iv) => {
		if (encryptedConfig && typeof encryptedConfig === 'string' && iv) {
			const decryptedConfig = await CryptoHelper.decodeData(encryptedConfig, iv, props?.secretKey)
			if (decryptedConfig) return JSON.parse(decryptedConfig)
			return null
		}
		return encryptedConfig
	}
	const handleConfig = async (configType, configData) => {
		if (configData?.[`${configType}Enable`] && configData?.[`${configType}Config`]) {
			const decryptedConfig = await getDecryptedConfig(configData[`${configType}Config`], configData?.iv)
			if (decryptedConfig && decryptedConfig.enable) {
				const finalConfig = {
					...decryptedConfig,
					orgId: configData?.orgId,
				}
				localStorage.setItem(`${configType}Config`, window.btoa(JSON.stringify(finalConfig)))
				if (configType === 'sso') {
					return finalConfig?.saml?.ssoURL
				}
			}
		}
	}
	// Function to trigger silent re-authentication after 30 minutes
	const startSilentSSO = async () => {
		let userEmailReport = localStorage.getItem('userEmailReport')
		if (userEmailReport) {
			let emailCheckData = JSON.parse(window.atob(userEmailReport))
			let isSsoLogin = localStorage.getItem('isSsoLogin')
			if (typeof emailCheckData === 'object') {
				if (emailCheckData?.adEnable && emailCheckData?.adConfig) {
				} else if (emailCheckData?.ssoEnable && emailCheckData?.ssoConfig) {
					if (emailCheckData && Object.keys(emailCheckData)?.length) {
						if (emailCheckData?.ssoEnable && emailCheckData?.ssoConfig) {
							let url = await handleConfig('sso', emailCheckData)
							console.log("Silent SSO Triggered <><><>", url)
							if (window.self !== window.top) {
								console.log("The code is running inside an iframe <><><>");
							} else {
								console.log("The code is NOT running inside an iframe <><><>");
								const iframe = document.getElementById('silent-sso-iframe')
								if (iframe) {
									iframe.src = url // Update the iframe's src to trigger silent SSO
								}
							}
						}
					}
				}
			}
		} else {
			console.log("No userEmailReport object found")
		}
	}
	// IFRAME Silent SSO
	// IFRAME will reload after successful SSO
	// This will force the Component (SilentSSO) restart
	// SSO will be retriggered after 15 minutes
	useEffect(async () => {
		console.log("useEffect initialized <><><>")
		// const interval = setInterval(async () => {
		const interval = setTimeout(async () => {
			console.log("Silent SSO Interval initiated <><><>")
			await startSilentSSO()
		}, 15 * 60 * 1000) // Every 15 minutes
		return () => clearInterval(interval) // Cleanup on component unmount
	}, [])
	return (
		<iframe
			id="silent-sso-iframe"
			// src="/silent-sso"
			style={{ display: 'none' }} // Keep the iframe hidden
			title="Silent SSO"
		/>
	)
}
const mapDispatchToProps = {}
const mapStateToProps = state => {
	console.log("state.login ====", state.login)
	return {
		login: state.login
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SilentSSO)
// export default SilentSSO