import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
      {
        props && props.value === 100 && <span style={{ color: 'green' }}>{'Successfully Uploaded'}</span>
      }
    </React.Fragment>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

const LinearWithValueLabel = (props) => {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    if (props.totalParts?.totalPartNumber && props.totalPartsLength) {
      let avg = (props.totalPartsLength / (props.totalParts?.totalPartNumber + 1)) * 100 // adding +1 for merge request 
      setProgress(avg)
      if (avg === 100 && props.uploadSuccess[props.index]) {
        let obj = { ...props.totalParts, "avg": avg }
        props.progressStatus(obj)
      }
    }
  }, [props.totalParts, props.totalPartsLengthCount, props.totalPartsLength, props.uploadSuccess[props.index],  Object.keys(props.uploadSuccess).length])

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} />
    </div>
  );
}

export default LinearWithValueLabel
